<template>
  <div>
    <v-card color="#1A347F">
      <div class="text-center justify-center">
        <div class="text-center mb-6 fn24-weight7" style="color: white">
          <div class="mx-16">Coupon</div>
          <div style="position: absolute; right: 10px; top: 0">
            <a-icon type="close" class="fn11" @click="cancel" />
          </div>
        </div>
        <div
          class="fn16 text-center pa-0"
          style="color: white; font-weight: 700"
        >
          Here is the Code for your redemption.
        </div>
      </div>

      <v-tabs
        v-model="tab"
        active-class="active"
        centered
        color="white"
        background-color="transparent"
      >
        <v-tab
          v-for="(item, index) in items"
          :key="index"
          class="fn12-weight7 text-center white--text text-capitalize"
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(item, index) in items"
          :key="index"
          style="background-color: #1a347f"
        >
          <v-card flat style="background-color: #1a347f">
            <div>
              <v-img
                :src="item.image"
                width="252"
                height="252"
                style="background-color: white"
                class="mx-auto mt-8 pa-0"
              />
            </div>
            <v-card-text style="color: white" class="fn12 text-center ma-0">
              {{ item.date }}
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-row class="my-2" style="color: #4f4f4f">
      <v-col cols="12" class="py-0 mt-2">
        <span class="fn12 text-decoration-underline" style="font-weight: 600"
          >terms & Conditions</span
        >
      </v-col>
      <v-col cols="12" class="pt-0">
        <span class="fn10">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui non
          mauris lobortis congue. Ut eu dui vitae praesent dolor nulla id.
          Cursus placerat commodo fringilla enim condimentum.
        </span>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      tab: null,
      items: [
        {
          name: "QR Code",
          date: "Valid until 21/12/2022",
          image: require("@/assets/QR_Code.png"),
        },
        {
          name: "Barcode",
          date: "Valid until 21/12/2022",
          image: require("@/assets/barcode.png"),
        },
        {
          name: "Code",
          date: "Valid until 21/12/2022",
          image: require("@/assets/QR_Code.png"),
        },
      ],
      text: "1234",
      id: "",
      shortDesc: "",
      name: "",
      desc: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
  created() {
    var licensesdata = JSON.parse(
      Decode.decode(localStorage.getItem("licensesdata"))
    );
    console.log("licensesdata", licensesdata);
    this.id = licensesdata.id;
    this.shortDesc = licensesdata.shortDesc;
    this.name = licensesdata.name;
    this.content = licensesdata.desc;
    this.showImage = licensesdata.blogImg;
    // this.picture_1 = licensesdata.blogImg;
  },
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.go(-1);
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiData"))
        );
        const auth = {
          headers: {
            Authorization: `Bearer ` + user.token,
          },
        };
        const data = {
          name: this.name,
        };
        if (this.activeFlag == true) {
          data.activeFlag = "1";
        } else if (this.activeFlag == false) {
          data.activeFlag = "0";
        }
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/licenses/` + this.id,
          data,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างใบประกอบวิชาชีพสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("Managelicenses");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `สร้างใบประกอบวิชาชีพไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.active {
  /* color: rgba(255, 255, 255, 0) !important; */
  background-color: rgba(255, 255, 255, 0) !important;
}
</style>
